$modalZIndex: 1350;

.TermsOfServiceModalWrapper {
  z-index: $modalZIndex;
}

.TermsOfServiceBackground {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0;
  background-color: rgb(242, 243, 239);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.TermsOfServiceModal {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: black;
  width: fit-content;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgba(53, 64, 82, 0.05) 0 0 14px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  padding: 3rem 2rem;
  column-gap: 1.75rem;
}

.TermsOfServiceHeader {
  font-family:
    Bricolage Grotesque,
    sans-serif;
  font-size: 2.25rem;
  margin: 0;
  font-weight: 600;
}

.TermsOfServiceBody {
  font-family: Inter, sans-serif;
  line-height: 1.5rem;
  margin: 0;
}

.TermsOfServiceContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.TermsOfServiceLink {
  color: black;
  font-weight: bold;
}

.TermsOfServiceActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.TermsOfServiceAcceptButton {
  font-family: Inter, sans-serif;
  font-size: 1rem;
  padding: 0.6875rem 1.75rem;
  border-radius: 8px;
  background-color: #f3573c;
  color: white;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover,
  &:focus {
    background-color: #a93405;
  }
  &:active {
    background-color: #942d04;
  }
  &:disabled {
    background-color: #a9aaa7;
  }
}

.TermsOfServiceDeclineButton {
  color: #0066cc;
  font-family: Inter, sans-serif;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-decoration-line: underline;
}
