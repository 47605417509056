.DownloadAppsContainer {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  flex-direction: column;
  left: 17px;
  bottom: 32px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #000;
  color: #f24a07;
  z-index: 1320;
  width: 217px;

  & > span {
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    padding: 12px 16px 7px;
  }
}

.DownloadAppsContainer a {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  text-decoration: none;
  padding: 12px 16px;
  gap: 10px;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: var(--Neutral-Neutral-Dark, #555554);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--Neutral-Neutral-Dark, #555554);
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  & > span {
    color: var(--Smoke-Gray-S50, #fefefd);
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: none;
  }
}
