.LogoutButton {
  background-color: #222222;
  outline: 0;
  border: 0 currentcolor;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  border-radius: 16px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;

  & img {
    margin-left: 4px;
  }

  &:hover {
    background-color: #444444;
  }
}
