.LoaderPage {
  .Loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 40px;
      height: 40px;
      margin: -20px 0 0 -20px;
      animation: spin 4s linear infinite;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
