.Button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0 currentcolor;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family:
    Manrope,
    Nunito,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  text-transform: none;
  min-width: 64px;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  box-shadow: none;
  line-height: 1rem;
  color: rgb(54, 56, 75);
  font-weight: 600;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    text-decoration: none;
    background-color: rgba(60, 74, 90, 0.04);
  }

  &.primary {
    background: rgb(34, 34, 34);
    color: rgb(255, 255, 255);

    &:disabled {
      color: rgb(255, 255, 255);
      background-color: rgb(89, 89, 89);
      border: none;
    }
  }
}
